
import ProfileDropdown from '@/views/layouts/navigation/ProfileDropdown.vue'
import { getCurrentInstance, onMounted, ref, defineComponent, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import {
  useStore
} from 'vuex'
import Button from 'primevue/button'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import moment from 'moment'

export default defineComponent({
  name: 'Countdown',
  components: {},
  setup() {
    const time = ref({
      hours: null,
      minutes: null,
      seconds: null
    })
    const store = useStore()

    const countdown = () => {
      const nextGenerate = moment().add(1, 'day').startOf('day')
      const now = moment()
      const diffTime = nextGenerate.unix() - now.unix()
      let duration:any = moment.duration(diffTime * 1000, 'milliseconds')
      const interval = 1000

      if (diffTime === 0) {
        store.dispatch('setReloadRoster')
      } else {
        setInterval(() => {
          duration = moment.duration(duration - interval, 'milliseconds')
          // console.log([duration.hours(), duration.minutes(), duration.seconds()])
          time.value.hours = duration.hours() < 10 ? `0${duration.hours()}` : duration.hours()
          time.value.minutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes()
          time.value.seconds = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds()
        }, 1000)
      }
    }

    onMounted(() => {
      // console.log('nextGenerate', nextGenerate.unix())
      // console.log('now', now.unix())
      countdown()
    })

    return {
      time
    }
  }
})
