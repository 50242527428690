/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IRosterState, IDaterange } from './model'
import { IRootState } from '@/store/model'

const state: IRosterState = {
  dateRange: {
    start: null,
    end: null
  },
  isReload: false,
  isReloadDetail: false
}

const mutations: MutationTree<IRosterState> = {
  SET_ROSTER_DATERANGE: (state, data) => {
    state.dateRange.start = data.start
    state.dateRange.end = data.end
  },
  SET_RELOAD_ROSTER: (state, isReload: boolean) => {
    console.log('isReload', isReload)
    state.isReload = isReload
    if (isReload) {
      setTimeout(() => {
        state.isReload = !isReload
      }, 2000)
    }
  },
  SET_RELOAD_DETAIL_ROSTER: (state, isReload: boolean) => {
    console.log('isReload', isReload)
    state.isReloadDetail = isReload
    if (isReload) {
      setTimeout(() => {
        state.isReloadDetail = !isReload
      }, 2000)
    }
  }
}

const actions: ActionTree<IRosterState, IRootState> = {
  setRosterDaterange: ({ commit }, data: IDaterange) => {
    commit('SET_ROSTER_DATERANGE', data)
  },
  setReloadRoster: ({ commit }) => {
    commit('SET_RELOAD_ROSTER')
  },
  setReloadDetailRoster: ({ commit }) => {
    commit('SET_RELOAD_DETAIL_ROSTER')
  }
}

const getters: GetterTree<IRosterState, IRootState> = {
  isReload: (state) => state.isReload
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
