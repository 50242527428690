
export default {
  name: 'ListActions',
  props: {
    edit: {
      type: Boolean,
      default: () => false
    },
    editTitle: {
      type: String,
      default: 'Edit'
    },
    del: {
      type: Boolean,
      default: () => false
    },
    delTitle: {
      type: String,
      default: 'Hapus'
    },
    copy: {
      type: Boolean,
      default: () => false
    },
    copyTitle: {
      type: String,
      default: 'Copy'
    },
    reset: {
      type: Boolean,
      default: () => false
    },
    resetTitle: {
      type: String,
      default: 'Reset'
    },
    change: {
      type: Boolean,
      default: () => false
    },
    changeTitle: {
      type: String,
      default: 'Ubah'
    },
    editRoster: {
      type: Boolean,
      default: () => false
    },
    editRosterTitle: {
      type: String,
      default: 'Ubah'
    },
    deactiveRoster: {
      type: Boolean,
      default: () => false
    },
    deactiveRosterTitle: {
      type: String,
      default: 'Ubah'
    },
    changeStatusRoster: {
      type: Boolean,
      default: () => false
    },
    changeStatusRosterTitle: {
      type: String,
      default: 'Ubah'
    },
    changeVehicleRoster: {
      type: Boolean,
      default: () => false
    },
    changeVehicleRosterTitle: {
      type: String,
      default: 'Ubah'
    },
    viewPassengerLog: {
      type: Boolean,
      default: () => false
    },
    viewPassengerLogTitle: {
      type: String,
      default: 'Ubah'
    }
  }
}
