import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_upload = _resolveComponent("file-upload", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_file_upload, {
      class: "p-0 m-0",
      extensions: _ctx.extensions,
      accept: _ctx.accept,
      size: _ctx.size,
      onInputFile: _ctx.onInputFile,
      ref: "refBtnFileUpload",
      "input-id": _ctx.inputId
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["extensions", "accept", "size", "onInputFile", "input-id"])
  ]))
}