enum StatusVerbal {
  Process = 'Diproses',
  Penalty = 'Sanksi Denda',
  Cancelled = 'Dibatalkan',
  Closed = 'Dipending',
}

enum StatusVerbalCode {
  Process = 'Process',
  Penalty = 'Penalty',
  Cancelled = 'Cancelled',
  Closed = 'Closed'
}

export {
  StatusVerbal,
  StatusVerbalCode
}
