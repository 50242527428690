/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Nullable from '@/utils/types/tNullable'
import { IRootState } from '@/store/model'
import { IPlottingState, IFilter, ISelectedConductor, ISelectedDriver, ISelectedHelper } from './model'

const state: IPlottingState = {
  // selectedConductor: { label: 'Zaniz', value: 'BEC89373-04C8-44FC-81D7-08DBCAEAC138', username: 'zaniz' },
  selectedConductor: null,
  selectedDriver: null,
  selectedHelper: null,
  filter: {
    trayek: [],
    class: [],
    optionTol: []
  }
}

const mutations: MutationTree<IPlottingState> = {
  SET_FILTER_TRAYEK: (state, value: IFilter[]) => {
    state.filter.trayek = value
  },
  SET_FILTER_CLASS: (state, value: IFilter[]) => {
    state.filter.class = value
  },
  SET_FILTER_TOL: (state, value: IFilter[]) => {
    state.filter.optionTol = value
  },
  SET_SELECTED_CONDUCTOR: (state, value: Nullable<ISelectedConductor>) => {
    state.selectedConductor = value
  },
  SET_SELECTED_DRIVER: (state, value: Nullable<ISelectedDriver>) => {
    state.selectedDriver = value
  },
  SET_SELECTED_HELPER: (state, value: Nullable<ISelectedHelper>) => {
    state.selectedHelper = value
  },
  DEFAULT_PLOTTING_STATE: (state) => {
    state.filter.class = []
    state.filter.optionTol = []
    state.filter.trayek = []
    state.selectedConductor = null
    state.selectedDriver = null
    state.selectedHelper = null
  },
  DEFAULT_PLOTTING_FILTERS_STATE: (state) => {
    state.filter.class = []
    state.filter.optionTol = []
    state.filter.trayek = []
  }
}

const actions: ActionTree<IPlottingState, IRootState> = {
  setFilterTrayek: ({ commit, state }, param: IFilter) => {
    const tempList = state.filter.trayek
    const findTrayek = tempList.findIndex((v) => v.value === param.value)
    if (findTrayek < 0) {
      tempList.unshift(param)
    } else {
      tempList.splice(findTrayek, 1)
    }
    commit('SET_FILTER_TRAYEK', tempList)
  },
  setFilterClass: ({ commit, state }, param: IFilter) => {
    const tempList = state.filter.class
    const findClass = tempList.findIndex((v) => v.value === param.value)
    if (findClass < 0) {
      tempList.unshift(param)
    } else {
      tempList.splice(findClass, 1)
    }
    commit('SET_FILTER_CLASS', tempList)
  },
  setFilterTol: ({ commit, state }, param: IFilter) => {
    const tempList = state.filter.optionTol
    const findTol = tempList.findIndex((v) => v.value === param.value)
    if (findTol < 0) {
      tempList.unshift(param)
    } else {
      tempList.splice(findTol, 1)
    }
    commit('SET_FILTER_TOL', tempList)
  },
  deleteFilterTrayek: ({ commit, getters }, param: IFilter) => {
    const tempList = getters.filterTrayek
    const findTrayek = tempList.findIndex((v: IFilter) => v.value === param.value)
    tempList.splice(findTrayek, 1)
    commit('SET_FILTER_TRAYEK', tempList)
  },
  deleteFilterClass: ({ commit, getters }, param: IFilter) => {
    const tempList = getters.filterClass
    const findClass = tempList.findIndex((v: IFilter) => v.value === param.value)
    tempList.splice(findClass, 1)
    commit('SET_FILTER_CLASS', tempList)
  },
  deleteFilterTol: ({ commit, getters }, param: IFilter) => {
    const tempList = getters.filterTol
    const findTol = tempList.findIndex((v: IFilter) => v.value === param.value)
    tempList.splice(findTol, 1)
    commit('SET_FILTER_TOL', tempList)
  },
  setSelectedConductor: ({ commit }, param: Nullable<ISelectedConductor>) => {
    commit('SET_SELECTED_CONDUCTOR', param)
  },
  setSelectedDriver: ({ commit }, param: Nullable<ISelectedDriver>) => {
    commit('SET_SELECTED_DRIVER', param)
  },
  setSelectedHelper: ({ commit }, param: Nullable<ISelectedHelper>) => {
    commit('SET_SELECTED_HELPER', param)
  },
  flushPlotting: ({ commit }) => {
    commit('DEFAULT_PLOTTING_STATE')
  },
  flushPlottingFilters: ({ commit }) => {
    commit('DEFAULT_PLOTTING_FILTERS_STATE')
  }
}

const getters: GetterTree<IPlottingState, IRootState> = {
  filterTrayek: (state): IFilter[] => state.filter.trayek,
  filterClass: (state): IFilter[] => state.filter.class,
  filterTol: (state): IFilter[] => state.filter.optionTol,
  selectedConductor: (state): Nullable<ISelectedConductor> => state.selectedConductor,
  selectedDriver: (state): Nullable<ISelectedDriver> => state.selectedDriver,
  selectedHelper: (state): Nullable<ISelectedHelper> => state.selectedHelper
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
