interface IBreadcrumb {
  icon?: any,
  title: string,
  routeName?: string,
}

const breadcrumbs: IBreadcrumb[] = []

export default {
  breadcrumbs,
}
