/* eslint-disable */
import { Response } from '@/utils/helpers/response'
import Repository from '@/data/repository'
import api from '@/configs/api_hjex'
import {
  Remote,
  remoteEnum
} from '@/data/remote/remote'
import apiUseCase from '../apiUseCase'
import { TypeTerminals } from '@/utils/interfaces/iCityTerminal'

const endpoint = 'management/v1/MsStation'
class CityTerminalUseCase {
  getAllKota(search: string = ''): Promise<any> {
    const arrParams = []
    let paramsString = ''
    if (search) {
      arrParams.push(`query=${search}`)
    }
    paramsString = `?${arrParams.join('&')}`
    return api.get(`hjexbackend/v1/MsKota${paramsString}`, {})
  }

  getAll(filter: any = null, filterField: any = []): Promise<Response> {
    const arrParams = []
    let paramsString = ''

    if (filter.search) {
      if (filterField.length > 0) {
        filterField.forEach((val: any) => {
          arrParams.push(`${val}=${filter.search.toLowerCase()}`)
        })
      }
    }
    if (filter.pageSize > 0) {
      arrParams.push(`pageSize=${filter.pageSize}`)
    }
    if (filter.pageNumber > 0) {
      arrParams.push(`pageNumber=${filter.pageNumber}`)
    }
    // arrParams.push(`activeOnly=${filter.isActive}`)
    paramsString = `?${arrParams.join('&')}`
    return new Repository(new Remote(remoteEnum.get, `${endpoint}${paramsString}`), null).getResult(false)
  }

  submitData(data: Array<TypeTerminals>): Promise<Response> {
    const payload = data.map((val) => ({
      Id: val?.Id ? val.Id : null,
      Name: val.Name,
      Code: val.Code,
      Type: val.Type,
      Description: val.Description,
      Latitude: val.Latitude,
      Longitude: val.Longitude,
      IdCity: val.IdCity,
      CityName: val.CityName,
      IsDeleted: val.IsDeleted
    }))
    return apiUseCase.postOrPut(endpoint, null, payload)
  }

  deleteData(id: string = ''): Promise<Response> {
    return apiUseCase.delete(endpoint + '/ByCity', id)
  }

  deleteTerminal(id: string = ''): Promise<Response> {
    return apiUseCase.delete(endpoint, id)
  }

  getDetail(id: any): Promise<Response> {
    return new Repository(new Remote(remoteEnum.get, `${endpoint}/${id}`), null).getResult(false)
  }

  checkStationUsed(id: string): Promise<Response> {
    return new Repository(new Remote(remoteEnum.get, `${endpoint}/check-station-used/${id}`), null).getResult(false)
  }

  searchStation(filter: any = null): Promise<Response> {
    const arrParams = []
    let paramsString = ''

    if (filter.search) {
      arrParams.push(`query=${filter.search}`)
    }
    if (filter.pageSize > 0) {
      arrParams.push(`pageSize=${filter.pageSize}`)
    }
    if (filter.pageNumber > 0) {
      arrParams.push(`pageNumber=${filter.pageNumber}`)
    }
    // arrParams.push(`activeOnly=${filter.isActive}`)
    paramsString = `?${arrParams.join('&')}`
    return new Repository(new Remote(remoteEnum.get, `${endpoint}/Search${paramsString}`), null).getResult(false)
  }
}

const cityTerminalUseCase = new CityTerminalUseCase()

export {
  cityTerminalUseCase
}
