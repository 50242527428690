
import Card from 'primevue/card'

import HeaderHjpTable from '@/views/components/header/HeaderHjpTable.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import ProgressSpinner from 'primevue/progressspinner'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'

import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRefs,
  computed,
  PropType,
  watch,
  getCurrentInstance
} from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import $ from 'jquery'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  IConfirmDialogDelete
} from '@/utils/interfaces/iHjpDataTable'
import IResponse from '@/utils/interfaces/iResponse'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import EmptyState from './EmptyState.vue'

const HjpDataList = defineComponent({
  name: 'HjpDataList',
  components: {
    Card,
    HeaderHjpTable,
    Paginator,
    ProgressSpinner,
    HjpSideBar,
    HjpCofirmModal,
    EmptyState
  },
  props: {
    // Props ini digunakan untuk define endpoint sesuai dengan resource data
    endpoint: {
      type: String,
      default: () => null,
    },
    // Header title card
    headerTitle: {
      type: String,
      default: () => 'New Page'
    },
    // Button title
    buttonTitle: {
      type: String,
      default: () => 'Tambah'
    },
    // Placeholder search
    searchPlaceholder: {
      type: String,
      default: () => 'Cari Disini...'
    },
    // Berisi atribute yg digunakan untuk mengisi content Confirm Dialog
    confirmDialogDelete: {
      type: Object as PropType<IConfirmDialogDelete>,
    },
    // Untuk menampilkan form di halaman Baru
    isOpenNewPage: {
      type: Boolean,
      default: () => false
    },
    // Props untuk route form gunakan name route
    baseRoute: {
      type: String,
      default: () => null,
    },
    // Props untuk field searching dan dikirim ke endpoint
    fieldSearch: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    totalRow: {
      type: Number,
      default: 10
    },
    isCustomNoData: {
      type: Boolean,
      default: false
    },
    textNoData: {
      type: String,
      default: 'Data belum ada, silahkan tambah untuk mengisi data'
    },
    searchValue: {
      type: String,
      default: ''
    },
    showAddBtn: {
      type: Boolean,
      default: true
    }
  },
  setup(props: any, { emit }: any) {
    const inputSearch = ref('')
    const store = useStore()
    const router = useRouter()
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const {
      endpoint,
      isOpenNewPage,
      baseRoute,
      fieldSearch,
      filters,
      totalRow,
      searchValue
    } = toRefs(props)
    const paginate = reactive({
      pageNumber: 1,
      pageSize: totalRow.value
    })
    const totalRecords = ref(0)
    const dataSource = ref([])
    const isLoading = ref(false)
    const showModalForm = computed({
      get: () => store.state.hjpDataList.modalForm.show,
      set: (value) => {
        store.dispatch('setShowModalFormDl', value)
      }
    })
    const showConfirmDialog = computed({
      get: () => store.state.hjpDataList.modalConfirmation.show,
      set: (value) => {
        store.dispatch('setShowModalConfirmationDl', value)
      }
    })
    const dataRow = computed(() => store.state.hjpDataList.modalForm.data) as any
    const hideModalForm = () => {
      showModalForm.value = false
    }
    const getData = () => {
      store.dispatch('flushRowDataList')
      isLoading.value = true
      const searchPrams = inputSearch.value ? inputSearch.value : searchValue.value
      apiUseCase.get(`${endpoint.value}${returnUrlPrams({
        pageNumber: paginate.pageNumber,
        pageSize: paginate.pageSize,
        search: searchPrams,
        filterField: fieldSearch.value,
        custom: filters.value
      })}`).then((response) => {
        const {
          result,
          count
        } = response
        dataSource.value = result
        totalRecords.value = count
        isLoading.value = false
      }).catch((err: any) => {
        isLoading.value = false
      })
    }
    const onClickBtnAdd = () => {
      if (isOpenNewPage.value) {
        router.push({
          // path: baseRoute.value
          name: `${baseRoute.value}-form`
        })
      } else {
        showModalForm.value = true
      }
    }
    const onSearch = (value: any) => {
      // console.log('onSearch', value)
      inputSearch.value = value
      getData()
    }
    const onChangePage = (evt: any) => {
      // console.log('onChangePage', evt)
      // paginate.pageNumber = (evt.page + 1) * paginate.top
      paginate.pageNumber = evt.page + 1
      // console.log('obj pagin', paginate)
      getData()
      const container = $('.wrap-hjp-data-list')
      $(window).scrollTop(container.offset().top)
    }
    const hideDialogConfirmation = () => {
      store.dispatch('flushRowDataList')
      showConfirmDialog.value = false
    }
    const submitFromDialog = () => {
      const idDelete = dataRow.value.Id
      store.dispatch('showLoading')
      apiUseCase.delete(endpoint.value, idDelete).then((res: IResponse) => {
        if (!res.error) {
          $toast.add({
            severity: 'success',
            detail: 'Data berhasil dihapus',
            group: 'bc',
            closable: false,
            life: 3000
          })
          getData()
        } else {
          $toast.add({
            severity: 'error',
            detail: res.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }
    watch(() => store.getters.reloadDataList, (val) => {
      if (val) {
        getData()
      }
    })
    onMounted(() => {
      getData()
    })
    return {
      onClickBtnAdd,
      onSearch,
      inputSearch,
      paginate,
      onChangePage,
      dataSource,
      isLoading,
      showModalForm,
      hideModalForm,
      showConfirmDialog,
      hideDialogConfirmation,
      submitFromDialog,
      totalRecords,
      getData
    }
  }
})
export default HjpDataList
