
import Sidebar from 'primevue/sidebar'
import { defineComponent, toRefs, reactive, computed, ref, watch } from 'vue'
import {
  useStore
} from 'vuex'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'

export default defineComponent({
  name: 'HjpSideBar',
  components: {
    Sidebar,
    HjpCofirmModal
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    canDelete: {
      type: Boolean,
      default: () => true
    },
    confirmDialogDelete: {
      type: Object,
      default: () => ({
        title: '',
        subTitle: '',
        btnSubmitTitle: '',
        btnBackTitle: '',
        imgContent: require('@/assets/img/modal-confirmation/delete-default.svg')
      })
    }
  },
  emits: ['hideDialog', 'onDelete'],
  setup(props, { emit }: any) {
    const propertiesProps = reactive(props)
    const store = useStore()
    const dataForm = computed(() => store.state.hjpTable.modal.data || store.state.hjpDataList.modalForm.data)
    const show = computed({
      get: () => propertiesProps.show,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
          store.dispatch('setTitleFormSidebar', null)
          store.dispatch('setTitleFormSidebarDl', null)
        }
      }
    })
    const titleModal = computed(() => store.state.hjpTable.modal.titleForm || store.state.hjpDataList.modalForm.titleForm)
    const attrModalConfirmation: any = reactive({
      show: false,
      ...propertiesProps.confirmDialogDelete
    })
    const onDelete = () => {
      // store.dispatch('setShowModal', false)
      attrModalConfirmation.show = true
    }
    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }
    const onSubmitConfirmation = () => {
      emit('onDelete', dataForm.value.id)
      show.value = false
      hideDialogConfirmation()
    }

    watch(() => propertiesProps.show, (val) => {
      if (!val) {
        store.dispatch('setTitleFormSidebar', null)
        store.dispatch('setTitleFormSidebarDl', null)
      }
    })
    return {
      show,
      onDelete,
      attrModalConfirmation,
      hideDialogConfirmation,
      onSubmitConfirmation,
      dataForm,
      titleModal
    }
  }
})
