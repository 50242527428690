import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IGlobalSetting } from '@/utils/interfaces/iGlobalSetting'
import apiUseCase from '@/usecase/apiUseCase'
import { IGlobalSettingState } from './model'
import { IRootState } from '@/store/model'

const states: IGlobalSettingState = {
  default: []
}

const mutations: MutationTree<IGlobalSettingState> = {
  SET_GLOBAL_SETTING: (state, data: IGlobalSetting[]) => {
    state.default = data
  },
}

const actions: ActionTree<IGlobalSettingState, IRootState> = {
  fetchGlobalSetting: async ({ commit }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const { error, result } = await apiUseCase.get('/settings/v1/GlobalSetting')
      if (!error) {
        commit('SET_GLOBAL_SETTING', result)
      }
    } catch (error) {
      throw error
    }
  },
}

const getters: GetterTree<IGlobalSettingState, IRootState> = {
  maxPp: (state): IGlobalSetting | undefined => state.default.find((v) => v.Code === 'PP'),
}

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
  getters
}
