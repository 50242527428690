import { App } from 'vue'
import primeComponents from './ui/primeComponents'
import icons from './ui/icons'
import toast from './ui/toast'
import vueGeolocation from './vue-geolocation'
import snackbar from './ui/snackbar'
import vCalendar from './ui/vCalendar'
import silentBox from './ui/silentBox'
import confirmationService from './ui/confirmationService'
import mitt from './ui/mitt'
import mqtt from './mqtt'

const definePlugin = (app: App<Element>) => {
  const vue = app
  vue.use(primeComponents)
  vue.use(icons)
  vue.use(toast)
  vue.use(vueGeolocation)
  vue.use(snackbar)
  vue.use(vCalendar)
  vue.use(silentBox)
  vue.use(confirmationService)
  vue.use(mitt)
  vue.use(mqtt)
}

export default definePlugin
