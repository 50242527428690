import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid lg:grid-cols-4 gap-2 items-center header-hjp" }
const _hoisted_2 = { class: "gap-4 sm:flex items-center col-span-1 lg:col-span-3" }
const _hoisted_3 = { class: "title-header" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "text-white font-bold text-sm" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-black font-bold text-sm" }
const _hoisted_8 = {
  key: 0,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputIconRight = _resolveComponent("InputIconRight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.titleHeader), 1),
      (_ctx.showAddBtn)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "mb-2 sm:mb-0 flex gap-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectAdd()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.iconButton ? _ctx.iconButton : _ctx.icon.svg.plusEva
              }, null, 8, _hoisted_4),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.titleButton), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.titleButton !== '' && _ctx.extraButton)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            class: "flex gap-2 extra-submit-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectAddExtraButton())),
            outlined: true
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.iconButton ? _ctx.iconButton : _ctx.icon.svg.plusBlackEva
              }, null, 8, _hoisted_6),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.titleExtraButton), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showSearch)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_InputIconRight, {
            class: "input-search-header-custom",
            placeholder: _ctx.placeholder,
            modelValue: _ctx.inputSearch,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputSearch) = $event)),
            canClick: true,
            icon: _ctx.icon.svg.searchEva,
            onSubmitInput: _ctx.onSearch,
            onKeyup: _withKeys(_ctx.onSearch, ["enter"])
          }, null, 8, ["placeholder", "modelValue", "icon", "onSubmitInput", "onKeyup"])
        ]))
      : _createCommentVNode("", true)
  ]))
}