// All regex expression can add in this file

/* Phone format with prefix zero number */
const phonePrefixZero = /^0?[1-9][0-9]{6,14}|$/

/* Cannot contain white space and special character */
const noWhiteSpaceAndSpecialChar = /^[a-zA-Z0-9]+|$/

/* Cannot use space prefix */
const notPrefixSpace = /^(?!\s+|$)/
// const notPrefixSpace = /^\S.*[a-zA-Z0-9\s]*$/

/* Cannot use space character */
const notSpaceChar = /^\S*$/

/* Cannot use space as the first character */
const notSpaceInFirstChar = /^\S.*[a-zA-Z0-9\s]*|$/

const rgxExp = {
  phonePrefixZero,
  noWhiteSpaceAndSpecialChar,
  notPrefixSpace,
  notSpaceChar,
  notSpaceInFirstChar
}

export default rgxExp
