/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IOtpState } from './model'
import { IRootState } from '@/store/model'

const state: IOtpState = {
  expDate: null,
  token: null
}

const mutations: MutationTree<IOtpState> = {
  SET_EXPDATE_OTP: (state, data) => {
    state.expDate = data
  },
  SET_TOKEN_OTP: (state, data) => {
    state.token = data
  },
  FLUSH_OTP: (state) => {
    state.expDate = null
    state.token = null
  }
}

const actions: ActionTree<IOtpState, IRootState> = {
  setExpdateOtp: ({ commit }, data: any) => {
    commit('SET_EXPDATE_OTP', data)
  },
  setTokenOtp: ({ commit }, data: any) => {
    commit('SET_TOKEN_OTP', data)
  },
  flushOtp: ({ commit }) => {
    commit('FLUSH_OTP')
  }
}

const getters: GetterTree<IOtpState, IRootState> = {
  getOtpExpdate: (state) => state.expDate,
  getOtpToken: (state) => state.token
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
