import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  createWebHistory
} from 'vue-router'
import moment from 'moment'
import environment from '@/configs/environtment'
import Main from '@/views/layouts/Main.vue'
import DataTable from '@/views/pages/coba/UsingTable.vue'
import CustomList from '@/views/pages/coba/UsingCustomList.vue'
import RouteTable from '@/views/pages/master-setting/route/List.vue'
import RouteForm from '@/views/pages/master-setting/route/Form.vue'
import VehicleTable from '@/views/pages/master-setting/vehicle/Table.vue'
import PassengerList from '@/views/pages/master-setting/passenger-type/Table.vue'
import ClassList from '@/views/pages/master-setting/class/Table.vue'
import Login from '@/views/pages/auth/Login.vue'
import LoginHjex from '@/views/pages/auth/LoginHjex.vue'
import CityTerminal from '@/views/pages/master-setting/city-terminal/CityTerminal.vue'
import CityTerminalForm from '@/views/pages/master-setting/city-terminal/Form.vue'
import FixedExpensesForm from '@/views/pages/master-setting/expenses/fixed-expenses/Form.vue'
import ConstraintType from '@/views/pages/master-setting/constraint-type/Table.vue'
import TrayekList from '@/views/pages/master-setting/trayek/List2.vue'
import TrayekReserveList from '@/views/pages/master-setting/trayek/reserve/List.vue'
import TrayekTouristList from '@/views/pages/master-setting/trayek/tourist/List.vue'
import TrayekAirportList from '@/views/pages/master-setting/trayek/airport/List.vue'
import ConductorList from '@/views/pages/conductor/List.vue'
import ConductorForm from '@/views/pages/conductor/Form.vue'
import Price from '@/views/pages/master-setting/price/Price.vue'
import PriceDetail from '@/views/pages/master-setting/price/active-price/active-price-detail/ActivePriceDetail.vue'
import ListController from '@/views/pages/controller/List.vue'
import VerpalReport from '@/views/pages/verpal-report/VerpalReport.vue'
import VerpalDetail from '@/views/pages/verpal-report/VerpalDetail.vue'
import VerbalDetail from '@/views/pages/verbal-report/detail/VerbalDetail.vue'
import GlobalSetting from '@/views/pages/global-setting/GlobalSettings.vue'
import Reports from '@/views/pages/reports/index.vue'
import ReportsDetail from '@/views/pages/reports/detail/DetailReport.vue'
import ReportsAdvance from '@/views/pages/reports/detail/ListReportAdvance.vue'
import PlottingConductorList from '@/views/pages/plotting-conductor/Index.vue'
import DriverList from '@/views/pages/driver/List.vue'
import HelperList from '@/views/pages/helper/List.vue'
import RosterContinueForm from '@/views/pages/roster/airport/component/RosterContinueForm.vue'

import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import store from '../store'

const TrayekForm = () => import('@/views/pages/master-setting/trayek/Form2.vue')
const TrayekMainList = () => import('@/views/pages/master-setting/trayek/main/List.vue')
const Expenses = () => import('@/views/pages/master-setting/expenses/Expenses.vue')
const RosterList = () => import('@/views/pages/roster/Index.vue')
const PariwisataRosterForm = () => import('@/views/pages/roster/form/AddRosterPariwisataForm.vue')
const PlottingConductorForm = () => import('@/views/pages/plotting-conductor/Form.vue')
const ListApproveDeposit = () => import('@/views/pages/approve-deposit/List.vue')
const ApprovalDepositDetail = () => import('@/views/pages/approve-deposit/Detail.vue')
const VerbalReport = () => import('@/views/pages/verbal-report/VerbalReport.vue')
const RosterAirportList = () => import('@/views/pages/roster/airport/Index.vue')

const baseRoute = '/hjp'
const checkUserInfo = () => {
  if (localStorage.getItem('userInfo')) {
    const userInfo = JSON.parse(encryptDecriptMethods.decrypt(localStorage.getItem('userInfo')))
    console.log('zczxcz', userInfo)
  }
  if (localStorage.getItem('rn') === 'External') {
    console.log('aaa')
    return 'reports-advance'
  }
  if (localStorage.getItem('ut')) {
    return 'dashboard'
  }
  return ''
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // eslint-disable-next-line consistent-return
    beforeEnter: async () => {
      const ut = localStorage.getItem('ut')
      if (ut) {
        return `${baseRoute}/`
        // eslint-disable-next-line no-else-return
      } else {
        console.log('environtment', environment.getHydraApi())
        // return 'hallo'
        window.location.href = `${environment.getHydraApi()}oauth2/auth?client_id=${environment.getHydraClientId()}&scope=${environment.getHydraScope()}&response_type=${environment.getHydraResponseType()}&state=${environment.getHydraState()}&redirect_uri=${window.location.origin}/callback&login_uri=${window.location.origin}/login`
      }
    },
    component: () => import('@/views/pages/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      // this meta property is used to define that the route can acces when user don't have token access
      hideForAuth: true
    }
  },
  {
    path: '/login-hjex',
    name: 'Login-hjex',
    component: LoginHjex,
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/auth/ForgotPassword.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/pages/auth/ChangePassword.vue')
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/pages/auth/Callback.vue'),
    // meta: {
    //   // hideForAuth: true
    //   requiresAuth: true
    // }
  },
  {
    path: '/ticket/:id?',
    name: 'ticket',
    component: () => import('@/views/pages/ticket/index.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/pages/privacy-policy/privacy-policy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import('@/views/pages/privacy-policy/terms-condition.vue')
  },
  {
    path: `${baseRoute}`,
    // eslint-disable-next-line no-nested-ternary
    // redirect: `${baseRoute}/${localStorage.getItem('ut') === null ? '' : store.state.userProfile?.RolesName === 'External' ? 'reports' : 'dashboard'}`,
    redirect: `${baseRoute}/${checkUserInfo()}`,
    name: 'Main',
    component: Main,
    meta: {
      // this meta property is used to define that the route can acces when user have token access
      requiresAuth: true
    },
    children: [
      {
        path: 'table',
        name: 'Table',
        component: DataTable,
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/index.vue'),
        meta: {
          group: 'DASHBOARD',
          title: 'Dashboard',
        }
      },
      {
        path: 'unclosed-transaction',
        name: 'unclosed-transaction',
        component: () => import('@/views/pages/dashboard/components/UnclosedTransaction.vue'),
        meta: {
          group: 'DASHBOARD',
          title: 'Dashboard',
        }
      },
      {
        path: 'departure-schedule',
        name: 'departure-schedule',
        component: () => import('@/views/pages/departure-schedule/DepartureSchedule.vue'),
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Jadwal Keberangkatan'
        }
      },
      {
        path: 'plotting-conductor',
        name: 'plotting-conductor',
        component: PlottingConductorList,
        meta: {
          group: 'PLOTING KONDEKTUR',
          title: 'Ploting Kondektur'
        }
      },
      {
        path: 'plotting-conductor-form',
        name: 'plotting-conductor-form',
        component: PlottingConductorForm,
        meta: {
          group: 'PLOTING KONDEKTUR',
          title: 'Ploting Kondektur'
        }
      },
      {
        path: 'roster-main',
        name: 'roster-main',
        component: RosterList,
        meta: {
          group: 'ROSTER',
          title: 'Roster'
        }
      },
      {
        path: 'tourist-roster-form',
        name: 'tourist-roster-form',
        component: PariwisataRosterForm,
        meta: {
          group: 'ROSTER',
          title: 'Roster'
        }
      },
      {
        path: 'roster-airport',
        name: 'roster-airport',
        component: RosterAirportList,
        meta: {
          group: 'ROSTER BANDARA',
          title: 'Roster Bandara'
        }
      },
      {
        path: 'roster-airport-form/:id?',
        name: 'roster-airport-form',
        component: RosterContinueForm,
        meta: {
          group: 'ROSTER BANDARA',
          title: 'Roster Bandara'
        }
      },
      {
        path: 'master-setting-bank',
        name: 'master-bank',
        component: () => import('@/views/pages/master-setting/bank/Table.vue'),
        meta: {
          group: 'MASTER',
          title: 'Master Bank'
        }
      },
      {
        path: 'master-setting-credential-bank',
        name: 'master-credential-bank',
        component: () => import('@/views/pages/master-setting/bank-credential/Table.vue'),
        meta: {
          group: 'MASTER',
          title: 'Master ID Credential Bank'
        }
      },
      {
        path: 'master-setting-partner-agent',
        name: 'master-partner-agent',
        component: () => import('@/views/pages/master-setting/partner-agent/Table.vue'),
        meta: {
          group: 'MASTER',
          title: 'Master Mitra Agen'
        }
      },
      {
        path: 'master-route',
        name: 'master-route',
        component: RouteTable,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Rute'
        }
      },
      {
        path: 'master-route/form/:id?',
        name: 'master-route-form',
        component: RouteForm,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Rute'
        }
      },
      {
        path: 'master-vehicle',
        name: 'master-vehicle',
        component: VehicleTable,
        meta: {
          group: 'MASTER',
          title: 'Master Kendaraan'
        }
      },
      {
        path: 'master-passenger-type',
        name: 'master-passenger-type',
        component: PassengerList,
        meta: {
          group: 'MASTER',
          title: 'Master Jenis Penumpang'
        }
      },
      {
        path: 'master-class',
        name: 'master-class',
        component: ClassList,
        meta: {
          group: 'MASTER',
          title: 'Master Kelas'
        }
      },
      {
        path: 'master-city-terminal',
        name: 'master-city-terminal',
        component: CityTerminal,
        meta: {
          group: 'MASTER',
          title: 'Master Kota & Terminal'
        }
      },
      {
        path: 'master-city-terminal/form/:id?',
        name: 'master-city-terminal-form',
        component: CityTerminalForm,
        meta: {
          group: 'MASTER',
          title: 'Master Kota & Terminal'
        }
      },
      {
        path: 'master-fixed-expenses',
        name: 'master-fixed-expenses',
        component: Expenses,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Pengeluaran'
        }
      },
      {
        path: 'master-fixed-expenses/form/:id?',
        name: 'master-fixed-expenses-form',
        component: FixedExpensesForm,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Pengeluaran'
        }
      },
      {
        path: 'master-constraint-type',
        name: 'master-constraint-type',
        component: ConstraintType,
        meta: {
          group: 'MASTER',
          title: 'Master Jenis Kendala'
        }
      },
      {
        path: 'master-price',
        name: 'master-price',
        component: Price,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Harga & Komisi'
        }
      },
      {
        path: 'master-price/detail/:id?',
        name: 'master-price-detail',
        component: PriceDetail,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Harga & Komisi'
        }
      },
      {
        path: 'master-trayek-main/form/:id?',
        name: 'master-trayek-main-form',
        component: TrayekForm,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Trayek'
        }
      },
      {
        path: 'master-trayek-reserve/form/:id?',
        name: 'master-trayek-reserve-form',
        component: TrayekForm,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Trayek'
        }
      },
      {
        path: 'master-trayek-tourist/form/:id?',
        name: 'master-trayek-tourist-form',
        component: TrayekForm,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Trayek'
        }
      },
      {
        path: 'master-trayek-airport/form/:id?',
        name: 'master-trayek-airport-form',
        component: TrayekForm,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Master Trayek'
        }
      },
      {
        path: 'master-trayek-main',
        name: 'master-trayek-main',
        component: TrayekMainList,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Trayek Utama'
        }
      },
      {
        path: 'master-trayek-reserve',
        name: 'master-trayek-reserve',
        component: TrayekReserveList,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Trayek Cadangan'
        }
      },
      {
        path: 'master-trayek-tourist',
        name: 'master-trayek-tourist',
        component: TrayekTouristList,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Trayek Pariwisata'
        }
      },
      {
        path: 'master-trayek-airport',
        name: 'master-trayek-airport',
        component: TrayekAirportList,
        meta: {
          group: 'PERSIAPAN KEBERANGKATAN',
          title: 'Trayek Bandara'
        }
      },
      {
        path: 'conductor-account',
        name: 'conductor-account',
        component: ConductorList,
        meta: {
          group: 'KRU',
          title: 'Akun Kondektur'
        }
      },
      {
        path: 'conductor-account/form/:id?',
        name: 'conductor-account-form',
        component: ConductorForm,
        meta: {
          group: 'KRU',
          title: 'Akun Kondektur'
        }
      },
      {
        path: 'controller-account',
        name: 'controller-account',
        component: ListController,
        meta: {
          group: 'KRU',
          title: 'Akun Kontroller'
        }
      },
      {
        path: 'controller-account/form/:id?',
        name: 'controller-account-form',
        component: ConductorForm,
        meta: {
          group: 'KRU',
          title: 'Akun Kontroller'
        }
      },
      {
        path: 'driver-account',
        name: 'driver-account',
        component: DriverList,
        meta: {
          group: 'KRU',
          title: 'Akun Sopir'
        }
      },
      {
        path: 'driver-account/form/:id?',
        name: 'driver-account-form',
        component: ConductorForm,
        meta: {
          group: 'KRU',
          title: 'Akun Sopir'
        }
      },
      {
        path: 'helper-account',
        name: 'helper-account',
        component: HelperList,
        meta: {
          group: 'KRU',
          title: 'Akun Pembantu'
        }
      },
      {
        path: 'helper-account/form/:id?',
        name: 'helper-account-form',
        component: ConductorForm,
        meta: {
          group: 'KRU',
          title: 'Akun Pembantu'
        }
      },
      {
        path: 'approve-deposits',
        name: 'approve-deposits',
        component: ListApproveDeposit,
        meta: {
          group: 'TRANSAKSI',
          title: 'Approve Setoran'
        }
      },
      {
        path: 'approve-deposits/detail/:id',
        name: 'approve-deposits-detail',
        component: ApprovalDepositDetail,
        meta: {
          group: 'TRANSAKSI',
          title: 'Approve Setoran'
        }
      },
      {
        path: 'verpal-report',
        name: 'verpal-report',
        component: VerpalReport,
        meta: {
          group: 'TRANSAKSI',
          title: 'Verpal'
        }
      },
      {
        path: 'verpal-report/detail/:id',
        name: 'verpal-report-detail',
        component: VerpalDetail,
        meta: {
          group: 'TRANSAKSI',
          title: 'Verpal'
        }
      },
      {
        path: 'verbal-report',
        name: 'verbal-report',
        component: VerbalReport,
        meta: {
          group: 'TRANSAKSI',
          title: 'Verbal'
        }
      },
      {
        path: 'verbal-report/detail/:id',
        name: 'verbal-report-detail',
        component: VerbalDetail,
        meta: {
          group: 'TRANSAKSI',
          title: 'Verbal'
        }
      },
      {
        path: 'global-settings',
        name: 'global-settings',
        component: GlobalSetting,
        meta: {
          group: 'SETTINGS',
          title: 'Global Settings'
        }
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
        meta: {
          group: 'DASHBOARD',
          title: 'Laporan'
        }
      },
      {
        path: 'reports-advance',
        name: 'reports-advance',
        component: ReportsAdvance,
        meta: {
          group: 'DASHBOARD',
          title: 'Laporan'
        }
      }
    ]
  },
  {
    path: '/reports/detail/:typeReport',
    name: 'reports-detail',
    component: ReportsDetail,
    meta: {
      group: 'DASHBOARD',
      title: 'Laporan'
    }
  },
  {
    path: '/xxx',
    name: 'xxx',
    component: () => import('@/views/shared-components/xxx.vue'),
    meta: {
      group: 'DASHBOARD',
      title: 'Laporan'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const checkDateExpired = (): boolean => {
  const token = localStorage.getItem('ut')
  const timeExpired = moment(localStorage.getItem('ei'))
  if (token && timeExpired) {
    const now = moment()
    const checkHours = moment.duration(now.diff(timeExpired)).asHours()
    if (checkHours <= 48) {
      return true
    }
  }
  store.dispatch('logoutAccount')

  return false
}

router.beforeEach((to, from, next) => {
  store.dispatch('hideLoading')
  const token = localStorage.getItem('ut')
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (checkDateExpired()) {
      if (token) {
        next()
      } else {
        next({ name: 'Home' })
      }
    } else {
      next({ name: 'Home' })
    }
  } else if (to.matched.some((record) => record.meta.hideForAuth)) {
    // if the user have token then he can access
    if (checkDateExpired()) {
      if (token) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
