
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import {
  ref, watch, defineComponent, PropType, toRefs
} from 'vue'
import { useRoute } from 'vue-router'
import {
  ITabView
} from '@/utils/interfaces/iHjpTabView'

const HjpTabView = defineComponent({
  name: 'HjpTabView',
  components: {
    TabView,
    TabPanel
  },
  props: {
    tabHeader: {
      type: Array as PropType<ITabView[]>,
      default: () => [{ title: 'test', error: false, value: 0 }],
    },
    currActive: {
      type: Number,
      default: () => 0
    }
  },
  emits: ['tabsChange'],
  // eslint-disable-next-line
  setup(props, { emit }) {
    const route = useRoute() as any
    const active = ref(props.currActive)

    const {
      tabHeader,
      currActive
    } = toRefs(props)

    const arrHeader = ref(tabHeader) as any

    watch(active, (newVal: any, oldVal: any) => {
      if (newVal !== oldVal) {
        emit('tabsChange', arrHeader.value[newVal].value, newVal)
      }
    })

    watch(currActive, (newVal) => {
      active.value = newVal
    })

    return {
      active,
      arrHeader,
      route
    }
  }

})
export default HjpTabView
