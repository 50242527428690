
import { defineComponent, toRefs } from 'vue'
import FileUpload from 'vue-upload-component'

interface ButtonFileUpload {
  extensions: string[] | string | RegExp,
  size: number,
  accept: string,
  inputId: string,
}

export default defineComponent({
  name: 'ButtonFileUpload',
  components: {
    FileUpload
  },
  props: {
    extensions: {
      type: String,
      default: () => 'jpg,jpeg,png',
    },
    size: {
      type: Number,
      default: () => 0
    },
    accept: {
      type: String,
      default: () => 'image/png,image/jpeg,image/jpg'
    },
    inputId: {
      type: String,
      required: true
    }
  },
  emits: ['onInputFile'],
  setup(props: ButtonFileUpload, { emit }: any) {
    const onInputFile = (newFile: any, oldFile: any) => {
      let file = null as any
      // console.log('new-file', newFile)
      // console.log('old-file', oldFile)
      const addBlob = () => {
        file.blob = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          file.blob = URL.createObjectURL(file.file)
        }
      }
      if (newFile && !oldFile) {
        // add
        // console.log('add', newFile)
        file = newFile
        addBlob()
      } else if (newFile && oldFile) {
        // update
        // console.log('edit', newFile)
        file = newFile
        addBlob()
      } else if (!newFile && oldFile) {
        // remove
        // console.log('remove', newFile)
        file = null
        // addBlob()
      }
      emit('onInputFile', file)
    }
    const {
      extensions,
      size,
      accept
    } = toRefs(props)
    return {
      onInputFile
    }
  }
})
