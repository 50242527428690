import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = {
  key: 0,
  class: "grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4"
}
const _hoisted_3 = { class: "bg-white px-6 py-4 rounded-lg w-full relative" }
const _hoisted_4 = { class: "flex items-center justify-between mb-4" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "desc pb-4" }
const _hoisted_7 = { class: "font-bold mb-2 lg:text-xl md:text-xl text-base" }
const _hoisted_8 = { class: "color-blue-grey lg:text-base md:text-base text-sm sm-truncate-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHjpTable = _resolveComponent("HeaderHjpTable")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_ListActions = _resolveComponent("ListActions")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_Card = _resolveComponent("Card")!
  const _component_HjpSideBar = _resolveComponent("HjpSideBar")!
  const _component_HjpCofirmModal = _resolveComponent("HjpCofirmModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, { class: "card-no-shadow" }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_HeaderHjpTable, {
            titleHeader: _ctx.headerTitle,
            titleButton: _ctx.buttonTitle,
            placeholder: _ctx.searchPlaceholder,
            onRedirectAdd: _ctx.onClickBtnAdd,
            onOnSearch: _ctx.onSearch
          }, null, 8, ["titleHeader", "titleButton", "placeholder", "onRedirectAdd", "onOnSearch"])
        ])
      ]),
      content: _withCtx(() => [
        (_ctx.dataSource?.length > 0)
          ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource, (data) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "flex w-full border-grey-2 rounded-lg",
                  key: data.Id
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "icon"),
                      _createElementVNode("i", {
                        class: "pi pi-ellipsis-h text-base cursor-pointer text-black",
                        "aria:haspopup": "true",
                        onClick: ($event: any) => (_ctx.showDropDown($event, data))
                      }, null, 8, _hoisted_5)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h6", _hoisted_7, _toDisplayString(data.Name), 1),
                      _createElementVNode("p", _hoisted_8, _toDisplayString(data.Description), 1)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_EmptyState, { key: 1 })),
        _createVNode(_component_OverlayPanel, { ref: "refOverlayAction" }, {
          default: _withCtx(() => [
            _createVNode(_component_ListActions, {
              edit: _ctx.isEdit,
              del: _ctx.isDelete,
              onDel: _ctx.onDelete,
              onEdit: _ctx.onEdit
            }, null, 8, ["edit", "del", "onDel", "onEdit"])
          ]),
          _: 1
        }, 512)
      ]),
      footer: _withCtx(() => [
        (_ctx.paginate.totalRecords > 0)
          ? (_openBlock(), _createBlock(_component_Paginator, {
              key: 0,
              rows: _ctx.paginate.pageSize,
              totalRecords: _ctx.paginate.totalRecords,
              onOnPage: _ctx.onChangePage
            }, null, 8, ["rows", "totalRecords", "onOnPage"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    _createVNode(_component_HjpSideBar, {
      show: _ctx.showModalForm,
      onHideDialog: _ctx.hideModalForm,
      confirmDialogDelete: _ctx.confirmDialogDelete,
      onOnDelete: _ctx.submitFromDialog
    }, _createSlots({ _: 2 }, [
      (_ctx.showModalForm)
        ? {
            name: "form",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "form")
            ])
          }
        : undefined
    ]), 1032, ["show", "onHideDialog", "confirmDialogDelete", "onOnDelete"]),
    _createVNode(_component_HjpCofirmModal, {
      show: _ctx.showConfirmDialog,
      title: _ctx.confirmDialogDelete?.title,
      subTitle: _ctx.confirmDialogDelete?.subTitle,
      btnSubmitTitle: _ctx.confirmDialogDelete?.btnSubmitTitle,
      imgContent: _ctx.confirmDialogDelete.imgContent,
      onHideDialog: _ctx.hideDialogConfirmation,
      onOnSubmit: _ctx.submitFromDialog
    }, null, 8, ["show", "title", "subTitle", "btnSubmitTitle", "imgContent", "onHideDialog", "onOnSubmit"])
  ]))
}