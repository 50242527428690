import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paginator = _resolveComponent("Paginator", true)!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_Paginator, {
      rows: _ctx.row,
      totalRecords: _ctx.total,
      onPage: _ctx.onPage,
      template: _ctx.template,
      rowsPerPageOptions: _ctx.rowsPerPageOption
    }, null, 8, ["rows", "totalRecords", "onPage", "template", "rowsPerPageOptions"])
  ]))
}