import { createApp } from 'vue'
import PrimeVue from 'primevue/config'
import moment from 'moment'
import 'moment/locale/id'
import App from './App.vue'
import router from './router'
import store from './store'
import definePlugin from './plugins/plugins'

import './registerServiceWorker'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'animate.css'
import '@/assets/styles/css/main.css'
import '@/assets/styles/css/tailwind.css'
import '@/assets/styles/scss/main.scss'
import 'vue-select/dist/vue-select.css'
import 'snackbar-vue/dist/snackbar-vue.common.css'

moment.locale('id')

const app = createApp(App)
if (process.env.NODE_ENV === 'development') {
  app.config.performance = true
}
app.use(store)
app.use(router)
app.use(PrimeVue, { ripple: true })
definePlugin(app)
app.mount('#app')
