
import './hjp-confirmation-modal.scss'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import {
  defineComponent,
  computed,
  reactive,
  ref
} from 'vue'

interface IPropsConfirmModal2 {
  imgContent: string
  title: string
  subTitle: string
  btnSubmitTitle: string
  btnBackTitle: string
}

export default defineComponent({
  name: 'ConfirmModal2',
  components: {
    Dialog,
    Button
  },
  props: {
    imgContent: {
      default: () => require('@/assets/img/modal-confirmation/delete-default.svg')
    },
    title: {
      type: String,
      default: 'Title'
    },
    subTitle: {
      type: String,
      default: 'Sub - Tile'
    },
    btnSubmitTitle: {
      type: String,
      default: 'Simpan Perubahan'
    },
    btnBackTitle: {
      type: String,
      default: 'Kembali'
    }
  },
  setup(props, { emit }) {
    let options = reactive<IPropsConfirmModal2>({
      imgContent: props.imgContent,
      title: props.title,
      subTitle: props.subTitle,
      btnSubmitTitle: props.btnSubmitTitle,
      btnBackTitle: props.btnBackTitle
    })
    const showModal = ref(false)
    const hide = () => {
      showModal.value = false
    }
    const show = () => {
      showModal.value = true
    }
    let resolving: any = null
    const closeDialog = () => {
      hide()
      resolving(false)
    }
    const openModal = (attrOption: Partial<IPropsConfirmModal2> | null = null) => {
      if (attrOption) {
        options = Object.assign(options, attrOption)
      }
      show()
      return new Promise<boolean>((resolve) => {
        resolving = resolve
      })
    }

    const onSubmit = () => {
      resolving(true)
      hide()
    }
    return {
      showModal,
      closeDialog,
      onSubmit,
      openModal,
      options
    }
  }
})
