interface IFilterPrams {
  search: string
  filterField: string[]
  custom: string[][]
  pageSize: number
  pageNumber: number,
  isSearcLowerCase: boolean
}

export default (filter: Partial<IFilterPrams> = {
  search: '',
  filterField: [],
  custom: [],
  pageSize: 0,
  pageNumber: 0,
  isSearcLowerCase: true
}): string => {
  const arrParams = []
  let paramsString = ''

  if (filter.search) {
    if (filter.filterField && filter.filterField.length > 0) {
      filter.filterField.forEach((val: string) => {
        arrParams.push(`${val}=${filter.isSearcLowerCase && filter?.search ? filter?.search.toLowerCase() : filter.search}`)
      })
    }
  }
  if (filter.custom && filter.custom.length > 0) {
    filter.custom.forEach((fil: string[]) => {
      if (fil.length > 0) {
        arrParams.push(`${fil[0]}${fil[1]}${fil[2]}`)
      }
    })
  }
  if (filter?.pageSize && filter.pageSize > 0) {
    arrParams.push(`pageSize=${filter.pageSize}`)
  }
  if (filter?.pageNumber && filter.pageNumber > 0) {
    arrParams.push(`pageNumber=${filter.pageNumber}`)
  }
  if (arrParams.length > 0) {
    paramsString = `?${arrParams.join('&')}`
  }

  return paramsString
}
