
import { TypeTerminals } from '@/utils/interfaces/iCityTerminal'

export default {
  namespaced: true,
  state: () => ({
    itemsTerminal: []
  }),
  mutations: {
    STORE_ITEMS_TERMINAL: (state: any, items: TypeTerminals[]) => {
      state.itemsTerminal = items
    }
  },
  actions: {
    storeItemTerminal: ({ commit }: any, items: TypeTerminals[]) => {
      commit('STORE_ITEMS_TERMINAL', items)
    }
  },
  // getters: { ... }
}
