import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "header flex" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dialog, {
      showHeader: false,
      visible: _ctx.showModal,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
      breakpoints: { '960px': '75vw', '640px': '90vw' },
      style: _normalizeStyle({ width: _ctx.width }),
      modal: true,
      contentClass: "wrap-ctn-modal-default"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("i", {
            class: "pi pi-times cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog()))
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "content")
        ])
      ]),
      _: 3
    }, 8, ["visible", "style"])
  ]))
}