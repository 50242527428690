
/* eslint-disable vue/no-dupe-keys */
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import {
  ref,
  toRefs,
  getCurrentInstance,
  defineComponent
} from 'vue'
import Button from 'primevue/button'
// import {
//   isEmpty
// } from 'lodash'

export default defineComponent({
  name: 'HeaderHjpTable',
  components: {
    InputIconRight,
    Button
  },
  props: {
    titleHeader: {
      default: () => 'Title'
    },
    titleButton: {
      default: () => 'Tambah'
    },
    iconButton: {
      default: () => null
    },
    placeholder: {
      default: () => 'Cari Disini...'
    },
    extraButton: {
      default: () => false
    },
    titleExtraButton: {
      default: () => 'Tambah'
    },
    showSearch: {
      default: () => true
    },
    showAddBtn: {
      default: () => true
    }
  },
  emits: ['onSearch', 'redirectAdd', 'redirectAddExtraButton'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $icon
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('')
    const {
      titleHeader,
      titleButton,
      iconButton,
      placeholder
    } = toRefs(props)
    const icon = $icon

    const onSearch = () => {
      emit('onSearch', inputSearch.value)
    }
    const redirectAdd = () => {
      emit('redirectAdd')
    }
    const redirectAddExtraButton = () => {
      emit('redirectAddExtraButton')
    }
    return {
      inputSearch,
      titleHeader,
      titleButton,
      iconButton,
      placeholder,
      onSearch,
      redirectAdd,
      redirectAddExtraButton,
      icon
    }
  }
})
