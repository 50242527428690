/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { IGlobalSettingHjex } from '@/utils/interfaces/iGlobalSetting'
import { IUserProfile } from '@/utils/interfaces/iUserResponse'
import moment from 'moment'
import { MutationTree } from 'vuex'
import { IRootState } from './model'
// import { ITokenResponse } from '@/utils/interfaces/iUserResponse'

const mutations: MutationTree<IRootState> = {
  SET_TOGGLE_MENU: (state, toggle: boolean) => {
    state.toggleMenu = toggle
  },
  SET_WIDTH: (state, width: number) => {
    state.device.width = width
    if (state.device.width < 1024) {
      state.toggleMenu = false
    }
  },
  SET_HEIGHT: (state, height: number) => {
    state.device.height = height
  },
  SET_CONNECTION: (state, isActive: boolean) => {
    state.network.connection = isActive
  },
  SET_CONNECTION_TYPE: (state, connectionType: string) => {
    state.network.connectionType = connectionType
  },
  SET_NO_INTERNET_MODAL: (state, noInternet: boolean) => {
    state.network.noInternetModal = noInternet
  },
  SET_SHOW_LOADING: (state, isLoad: boolean) => {
    state.loading = isLoad
  },
  SET_TOGGLE_SIDEBAR: (state, toggle: boolean) => {
    state.toggleSidebar = toggle
  },
  // Components Data Table
  SET_SHOW_MODAL: (state, showModal: boolean) => {
    state.hjpTable.modal.show = showModal
    if (!showModal) {
      state.hjpTable.modal.data = null
    }
  },
  STORE_ROW_DATA_TABLE: (state, rowData: any) => {
    state.hjpTable.modal.data = rowData
  },
  FLUSH_ROW_DATA_TABLE: (state, rowData: any) => {
    state.hjpTable.modal.data = rowData
  },
  SET_TITLE_FORM_SIDEBAR: (state, title: string) => {
    state.hjpTable.modal.titleForm = title
  },
  SET_RELOAD_DATA_TABLE: (state, isReload: boolean) => {
    state.hjpTable.reloadTable = isReload
    if (isReload) {
      setTimeout(() => {
        state.hjpTable.reloadTable = !isReload
      }, 2000)
    }
  },
  // Components Data List (DL: Data List)
  SET_SHOW_MODAL_FORM_DL: (state, show: boolean) => {
    state.hjpDataList.modalForm.show = show
    if (!show) {
      state.hjpDataList.modalForm.data = null
    }
  },
  STORE_ROW_DATA_LIST_DL: (state, rowData: any) => {
    state.hjpDataList.modalForm.data = rowData
  },
  SET_TITLE_FORM_SIDEBAR_DL: (state, title: string) => {
    state.hjpDataList.modalForm.titleForm = title
  },
  SET_SHOW_MODAL_CONFIRMATION_DL: (state, show: boolean) => {
    state.hjpDataList.modalConfirmation.show = show
  },
  SET_CONTENT_MODAL_CONFIRMATION_DL: (state: any, data: any) => {
    state.hjpDataList.modalConfirmation = {
      title: data.title,
      subTitle: data.subTitle,
      btnSubmitTitle: data.btnSubmitTitle,
      btnBackTitle: data.btnBackTitle,
      imgContent: data.imgContent
    }
  },
  SET_RELOAD_DATA_LIST: (state, isReload: boolean) => {
    state.hjpDataList.reloadData = isReload
    if (isReload) {
      setTimeout(() => {
        state.hjpDataList.reloadData = !isReload
      }, 2000)
    }
  },
  SET_USER_PROFILE: (state, data: IUserProfile | null | undefined) => {
    state.userProfile = data
  },
  SET_SELECTED_PASSENGER: (state, data: any) => {
    state.selectedPassenger = data
  },
  SET_SELECTED_PRICE_CHANGE: (state, data: any) => {
    state.selectedPriceChange = data
  },
  SET_GLOBAL_SETTING_HJEX: (state, data: Array<IGlobalSettingHjex>) => {
    state.globalSettingHjex = data
  },
  SET_TOGGLE_CHANGE_PASSWORD_DIALOG: (state, data: any) => {
    state.togglePasswordDialog = data
  },
  SET_RELOAD_RIT_1_DASHBOARD: (state, isReload: boolean) => {
    console.log('isReload', isReload)
    state.dashboard.reloadRit1 = isReload
    if (isReload) {
      setTimeout(() => {
        state.dashboard.reloadRit1 = !isReload
      }, 2000)
    }
  },
  SET_RELOAD_RIT_2_DASHBOARD: (state, isReload: boolean) => {
    console.log('isReload', isReload)
    state.dashboard.reloadRit2 = isReload
    if (isReload) {
      setTimeout(() => {
        state.dashboard.reloadRit2 = !isReload
      }, 2000)
    }
  },
  SET_TOGGLE_MINIMIZE_MENU: (state, toggle: boolean) => {
    state.toggleMinimizeMenu = toggle
  },
  SET_TOGGLE_DATATABLE_SIDEBAR_DELETE_BUTTON: (state, toggle: boolean) => {
    state.hjpTable.rightbarForm.canDelete = toggle
  },
  SET_RELOAD_TRAYEK: (state, isReload: boolean) => {
    state.trayek.isReload = isReload
    if (isReload) {
      setTimeout(() => {
        state.trayek.isReload = !isReload
      }, 2000)
    }
  },
  SET_SEARCH_FIELD: (state, data: any) => {
    state.fieldSearch = data
  },
  SET_TRAYEK_HEAD_SEARCH_FILTER: (state, data: any) => {
    state.trayek.filters.search = data
  },
  SET_TRAYEK_HEAD_CLASS_FILTER: (state, data: any) => {
    state.trayek.filters.classId = data
  },
  SET_TRAYEK_HEAD_ISTOL_FILTER: (state, data: any) => {
    state.trayek.filters.isTol = data
  },
  FLUSH_TRAYEK_HEAD_FILTER: (state, data: any) => {
    state.trayek.filters.search = data
    state.trayek.filters.isTol = data
    state.trayek.filters.classId = data
  },
  SET_TRAYEK_FORM1: (state, data: any) => {
    state.trayek.form.step1.name = data.Name
    state.trayek.form.step1.code = data.Code
    state.trayek.form.step1.class = data.Class
    state.trayek.form.step1.startDate = data.StartDate
    state.trayek.form.step1.endDate = data.EndDate
    state.trayek.form.step1.isTol = data.IsTol
    state.trayek.form.step1.rits = data.Rits
  },
  FLUSH_TRAYEK_FORM1: (state) => {
    state.trayek.form.step1.name = ''
    state.trayek.form.step1.code = ''
    state.trayek.form.step1.class = null
    state.trayek.form.step1.startDate = ''
    state.trayek.form.step1.endDate = ''
    state.trayek.form.step1.isTol = false
    state.trayek.form.step1.rits = [
      { rit: null, expense: null },
      { rit: null, expense: null }
    ]
  },
  SET_TRAYEK_FORM2: (state, data: any) => {
    state.trayek.form.step2.data = data
  },
  SET_TRAYEK_FORM_STEP: (state, data: any) => {
    state.trayek.formStep = data
  },
  SET_TRAYEK_PATH: (state, data: any) => {
    state.trayek.path = data
  },
  SET_HEADER_BUTTON: (state, data: any) => {
    state.headerButton.isShow = data.isShow
    state.headerButton.label = data.label
  },
  SET_HEADER_BUTTON_ACTION_SHOW_DIALOG_FORM: (state, data: any) => {
    state.headerButton.isShowDialogForm = data
  },
  FLUSH_HEADER_BUTTON: (state) => {
    state.headerButton.isShow = false
    state.headerButton.label = ''
  },
  SET_LISTBUS_TRAYEK: (state, data: any) => {
    state.trayek.listBus = data
  },
  SET_VEHICLE_MUTATION_FORM_STEP: (state: any, data: any) => {
    state.vehicleMutation.formStep = data
  },
  SET_FIRST_VEHICLE_MUTATION: (state: any, data: any) => {
    state.vehicleMutation.firstBus = data
  },
  SET_SECOND_VEHICLE_MUTATION: (state: any, data: any) => {
    state.vehicleMutation.secondBus = data
  },
  SET_DESCRIPTION_VEHICLE_MUTATION: (state: any, data: any) => {
    state.vehicleMutation.description = data
  },
  FLUSH_VEHICLE_MUTATION_FORM: (state: any) => {
    state.vehicleMutation.formStep = 1
    state.vehicleMutation.firstBus = null
    state.vehicleMutation.secondBus = null
    state.vehicleMutation.description = ''
  },
  SET_SHOW_DIALOG: (state: any, showModal: boolean) => {
    state.hjpTable.dialog.show = showModal
  },
}

export default mutations
