<template>
  <div class="flex items-center">
    <div class="flex items-center" v-for="(item, index) in list" :key="index">
      <div v-if="item.icon !== null" v-html="item.icon" class="mr-2"></div>
      <p :class="classHover(item)" @click="redirect(item)">
        {{ item.title }}
      </p>
      <div v-if="index < list.length - 1" class="mx-1" v-html="$icon.svg.arrowRightwardFillEva"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BreadcrumbMain',
  props: ['list'],
  methods: {
    redirect(item) {
      if (item.routeName !== null && item.routeName !== this.$route.name) {
        this.$router.push({ name: item.routeName })
        this.$store.dispatch('ui/setBreadcrumbs', [])
      }
    },
    classHover(item) {
      let classes = ''
      if (this.$route.name === item.routeName) {
        classes += 'color-blue-1 font-bold'
      }

      if (item.routeName !== null) {
        classes += ' cursor-pointer hover-color-blue-1'
      }
      return classes
    }
  }
}
</script>
