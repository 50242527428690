import { App } from 'vue'
import VueSilentbox from 'vue-silentbox'
import 'vue-silentbox/dist/style.css'

export default {
  install: (app: App<Element>) => {
    const vue = app
    vue.use(VueSilentbox)
  }
}
