/* eslint-disable */
import { Response } from '@/utils/helpers/response'
import Repository from '@/data/repository'
import api from '@/configs/api_hjex'
import {
  Remote as RemoteHjex,
  remoteEnum
} from '@/data/remote/remoteHjex'

const endpoint = 'hjexbackend/v2/msagens'
class HjexAgentUseCase {
  getAll(filter: any = null, filterField: any = []): Promise<Response> {
    const arrParams = []
    let paramsString = ''

    if (filter.search) {
      if (filterField.length > 0) {
        filterField.forEach((val: any) => {
          arrParams.push(`${val}=${filter.search.toLowerCase()}`)
        })
      }
    }
    arrParams.push('isActive=true')
    if (arrParams.length > 0) {
      paramsString = `?${arrParams.join('&')}`
    }
    // return api.get(`${endpoint}${paramsString}`, {})
    // return new Repository(new Remote(remoteEnum.get, `${endpoint}${paramsString}`), null).getResult(false)
    return new Repository((new RemoteHjex(remoteEnum.get, `${endpoint}${paramsString}`)), null).getResult(false)
  }
}

const hjexAgentUseCase = new HjexAgentUseCase()

export {
  hjexAgentUseCase
}
