
import Card from 'primevue/card'

import HeaderHjpTable from '@/views/components/header/HeaderHjpTable.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import ListActions from '@/views/components/list/ListActions.vue'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import OverlayPanel from 'primevue/overlaypanel'
import IResponse from '@/utils/interfaces/iResponse'

import { onMounted, reactive, ref, toRefs, defineComponent, computed, PropType, getCurrentInstance, watch } from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import $ from 'jquery'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
  IConfirmDialogDelete
} from '@/utils/interfaces/iHjpDataTable'
import EmptyState from './EmptyState.vue'

const HjpDataSmallCardWithIcon = defineComponent({
  name: 'HjpDataListWithIcon',
  components: {
    Card,
    HeaderHjpTable,
    HjpSideBar,
    ListActions,
    OverlayPanel,
    Paginator,
    HjpCofirmModal,
    EmptyState
  },
  props: {
    // Props ini digunakan untuk define endpoint sesuai dengan resource data
    endpoint: {
      type: String,
      default: () => null,
    },
    // Header title card
    headerTitle: {
      type: String,
      default: () => 'New Page'
    },
    // Button title
    buttonTitle: {
      type: String,
      default: () => 'Tambah'
    },
    // Placeholder search
    searchPlaceholder: {
      type: String,
      default: () => 'Cari Disini...'
    },
    // Berisi atribute yg digunakan untuk mengisi content Confirm Dialog
    confirmDialogDelete: {
      type: Object as PropType<IConfirmDialogDelete>,
    },
    masterName: {
      type: String,
      default: () => ''
    },
    isEdit: {
      default: () => true
    },
    isDelete: {
      default: () => true
    },
    // Untuk menampilkan form di halaman Baru
    isOpenNewPage: {
      type: Boolean,
      default: () => false
    },
    // Props untuk route form
    baseRoute: {
      type: String,
      default: () => null,
    },
    pageSize: {
      default: () => 10
    }
  },
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('')
    const store = useStore()
    const router = useRouter()
    const dataRow = ref(null) as any
    const refOverlayAction = ref()
    const {
      endpoint,
      isOpenNewPage,
      baseRoute,
      confirmDialogDelete,
      masterName,
      pageSize
    } = toRefs(props)
    confirmDialogDelete.value.imgContent = require('@/assets/img/modal-confirmation/delete-default.svg')
    const paginate = reactive({
      pageNumber: 1,
      pageSize: pageSize.value ?? 10,
      totalRecords: 0,
    })
    const dataSource = ref([])
    const isLoading = ref(false)
    const showModalForm = computed({
      get: () => store.state.hjpDataList.modalForm.show,
      set: (value) => {
        store.dispatch('setShowModalFormDl', value)
        if (!value) {
          store.dispatch('setTitleFormSidebarDl', null)
        }
      }
    })
    const showConfirmDialog = computed({
      get: () => store.state.hjpDataList.modalConfirmation.show,
      set: (value) => {
        store.dispatch('setShowModalConfirmationDl', value)
      }
    })

    const setDataRowOnRedux = () => {
      console.log('dataRow', dataRow.value)
      store.dispatch('storeRowDataListDl', dataRow.value)
    }

    const onEdit = (val: any) => {
      console.log('val', val)
      // console.log('onEdit', refOverlayAction.value)
      setDataRowOnRedux()
      // eslint-disable-next-line no-use-before-define
      hideOverlay()
      showModalForm.value = true
    }

    const onDelete = (val: any) => {
      showConfirmDialog.value = true
      setDataRowOnRedux()
      // eslint-disable-next-line no-use-before-define
      hideOverlay()
    }

    const hideModalForm = () => {
      showModalForm.value = false
    }

    const getData = () => {
      isLoading.value = true
      const arrParams = []
      let paramsString = ''

      if (inputSearch.value) {
        arrParams.push(`name=${inputSearch.value}`)
      }
      if (paginate.pageNumber > 0) {
        arrParams.push(`pageNumber=${paginate.pageNumber}`)
      }
      if (paginate.pageSize > 0) {
        arrParams.push(`pageSize=${paginate.pageSize}`)
      }
      paramsString = `?${arrParams.join('&')}`
      apiUseCase.get(`${endpoint.value}${paramsString}`).then((response) => {
        // console.log('res', response)
        dataSource.value = response.result
        // console.log('dataSource', dataSource.value)
        paginate.totalRecords = response.count
        // console.log('paginate', paginate.totalRecords)
        isLoading.value = false
      }).catch((err: any) => {
        isLoading.value = false
      })
    }

    const onSearch = (value: any) => {
      console.log('onSearch', value)
      inputSearch.value = value
      getData()
    }

    const onChangePage = (evt: any) => {
      // console.log('evt', evt)
      paginate.pageNumber = evt.page + 1
      getData()
      const container = $('.wrap-hjp-data-list')
      $(window).scrollTop(container.offset().top)
    }

    const hideDialogConfirmation = () => {
      showConfirmDialog.value = false
    }

    const submitFromDialog = (val: any = '') => {
      console.log('submitFromDialog')
      console.log('val', val)
      store.dispatch('showLoading')
      const deletedId = val === '' ? dataRow.value.Id : val
      apiUseCase.delete(endpoint.value, deletedId).then((res: IResponse) => {
        console.log('response delete', res)
        if (res.error) {
          $toast.add({
            severity: 'error',
            summary: `Tidak dapat menghapus ${masterName.value}`,
            detail: `${masterName.value} sudah digunakan oleh kondektur dan tidak dapat dihapus.`,
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'success',
            summary: `Berhasil menghapus ${masterName.value}`,
            detail: `${masterName.value} sudah dihapus oleh sistem dan tidak dapat digunakan kembali.`,
            group: 'bc',
            closable: false,
            life: 3000
          })
          getData()
        }
      }).catch((err: any) => {
        console.log('err delete', err)
      })
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    const showDropDown = (evt: any, item: any) => {
      console.log('evt', evt)
      console.log('item', item)
      dataRow.value = item
      console.log('overlay', refOverlayAction.value)
      refOverlayAction.value.toggle(evt)
    }

    const hideOverlay = () => {
      refOverlayAction.value.hide()
    }

    const onClickBtnAdd = (val: any) => {
      console.log('onClickBtnAdd', val)
      store.dispatch('flushRowDataTable')
      showModalForm.value = true
    }

    watch(() => store.getters.reloadTable, (val) => {
      if (val) {
        getData()
      }
    })

    onMounted(() => {
      getData()
      console.log('overlay', ref())
    })
    return {
      onClickBtnAdd,
      onSearch,
      inputSearch,
      paginate,
      onChangePage,
      dataSource,
      isLoading,
      showModalForm,
      hideModalForm,
      showConfirmDialog,
      hideDialogConfirmation,
      submitFromDialog,
      showDropDown,
      hideOverlay,
      refOverlayAction,
      onEdit,
      onDelete,
      confirmDialogDelete
    }
  }
})
export default HjpDataSmallCardWithIcon
