import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-stretch w-100 input-icon-right-grey w-full" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputText, {
      type: "text",
      id: "input-search",
      class: "none-right-radius left-radius w-full input-primary",
      style: _normalizeStyle($setup.inputBackground?{backgroundColor: $setup.inputBackground }:{}),
      modelValue: $setup.input,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.input) = $event)),
      placeholder: $setup.placeholder,
      autofocus: $setup.autofocus,
      disabled: $setup.disabled
    }, null, 8, ["style", "modelValue", "placeholder", "autofocus", "disabled"]),
    _createElementVNode("div", {
      class: _normalizeClass(($setup.canClick) ? 'background-grey-3 px-2 items-center flex right-radius cursor-pointer' : 'px-2 items-center flex right-radius'),
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.canClick && $setup.onClick()))
    }, [
      ($setup.icon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: $setup.icon
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", null, _toDisplayString($setup.label), 1)
          ]))
    ], 2)
  ]))
}