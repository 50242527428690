/* eslint-disable class-methods-use-this */
import { IPlotting } from '@/utils/interfaces/iPlotting'
import { Response } from '@/utils/helpers/response'
import apiUseCase from '../apiUseCase'

const endpoint = '/roster/v1/Plotting'

class PlottingUseCase {
  editPlotting(data: IPlotting): Promise<Response> {
    const payload = {
      ...data
    }
    return apiUseCase.put(`${endpoint}/${data.Id}`, payload)
  }
}

const plottingUseCase = new PlottingUseCase()

export { plottingUseCase }
