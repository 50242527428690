import { App } from 'vue'
import { SnackbarService, Vue3Snackbar } from 'vue3-snackbar'
import 'vue3-snackbar/dist/style.css'

export default {
  install: (app: App<Element>) => {
    const vue = app
    vue.use(SnackbarService)
    vue.component('vue3-snackbar', Vue3Snackbar)
  }
}
