import { App } from 'vue'
// import Button from 'primevue/button'
// import Card from 'primevue/card'
// import InputText from 'primevue/inputtext'
// import InputSwitch from 'primevue/inputswitch'
// import Sidebar from 'primevue/sidebar'
// import OverlayPanel from 'primevue/overlaypanel'
// import Password from 'primevue/password'
// import Calendar from 'primevue/calendar'
// import RadioButton from 'primevue/radiobutton'
// import Multiselect from 'primevue/multiselect'
// import Textarea from 'primevue/textarea'
// import Checkbox from 'primevue/checkbox'
// import Accordion from 'primevue/accordion'
// import AccordionTab from 'primevue/accordiontab'
// import ProgressSpinner from 'primevue/progressspinner'
import Toast from 'primevue/toast'
import Tooltip from 'primevue/tooltip'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmDialog from 'primevue/confirmdialog'

export default {
  install: (app: App<Element>) => {
    // app.component('Button', Button)
    // app.component('Card', Card)
    // app.component('InputText', InputText)
    // app.component('InputSwitch', InputSwitch)
    // app.component('Sidebar', Sidebar)
    // app.component('OverlayPanel', OverlayPanel)
    // app.component('Password', Password)
    // app.component('Calendar', Calendar)
    // app.component('RadioButton', RadioButton)
    // app.component('Multiselect', Multiselect)
    // app.component('Textarea', Textarea)
    // app.component('Checkbox', Checkbox)
    // app.component('Accordion', Accordion)
    // app.component('AccordionTab', AccordionTab)
    // app.component('ProgressSpinner', ProgressSpinner)
    app.component('Toast', Toast)
    app.directive('tooltip', Tooltip)
    app.component('ConfirmPopup', ConfirmPopup)
    app.component('ConfirmDialog', ConfirmDialog)
  }
}
