
import './hjp-alert-modal.scss'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import {
  defineComponent,
  computed,
  reactive,
  ref,
  useSlots
} from 'vue'

export default defineComponent({
  name: 'HjpCofirmModal',
  components: {
    Dialog,
    Button
  },
  props: {
    show: {
      type: Boolean,
      default: () => true
    },
    imgContent: {
      default: () => require('@/assets/img/modal-confirmation/changes-credential.svg')
    },
    title: {
      type: String,
      default: () => 'Title'
    },
    subTitle: {
      type: String,
      default: () => 'Sub - Tile'
    },
    btnSubmitTitle: {
      type: String,
      default: () => 'Simpan Perubahan'
    },
    btnBackTitle: {
      type: String,
      default: () => 'Kembali'
    }
  },
  emits: ['hideDialog', 'onSubmit'],
  setup(props, { emit }: any) {
    const slot = useSlots()
    const propertiesProps = reactive(props)
    const showModal = computed({
      get: () => propertiesProps.show,
      set: (val) => {
        if (!val) {
          emit('hideDialog')
        }
      }
    })
    const closeDialog = () => {
      showModal.value = false
    }
    const onSubmit = () => {
      emit('onSubmit')
    }
    return {
      showModal,
      closeDialog,
      onSubmit,
      slot
    }
  }
})
