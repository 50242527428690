/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { StoreOptions, createStore } from 'vuex'
import stateRoot from './state'
import mutationsRoot from './mutations'
import actionsRoot from './actions'
import gettersRoot from './getters'
import ui from './modules/ui'
import cityTerminal from './modules/cityTerminal'
import plotting from './modules/plotting'
import roster from './modules/roster'
import globalSetting from './modules/globalSetting'
import approvePp from './modules/approvePp'
import otp from './modules/otp'
import { IRootState } from './model'

const store: StoreOptions<IRootState> = {
  state: stateRoot,
  getters: gettersRoot,
  mutations: mutationsRoot,
  actions: actionsRoot,
  modules: {
    ui,
    cityTerminal,
    plotting,
    roster,
    globalSetting,
    approvePp,
    otp
  }
}

export default createStore(store)
