/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { IUserProfile } from '@/utils/interfaces/iUserResponse'
import { IVehicleMutation } from '@/utils/interfaces/iVehicleMutation'
import { IRootState } from './model'

const networking = {
  connection: true,
  connectionType: '',
  noInternetModal: false
}

const deviceType = {
  platform: 'web',
  isIos: false,
  isAndroid: false,
  isWeb: true,
  isMobile: false,
  width: 0,
  height: 0
}

const hjpTable = {
  reloadTable: false,
  modal: {
    show: false,
    data: null,
    titleForm: ''
  },
  rightbarForm: {
    canDelete: true
  },
  dialog: {
    show: false
  }
}

const hjpDataList = {
  modalConfirmation: {
    show: false,
    title: '',
    subTitle: '',
    btnSubmitTitle: '',
    btnBackTitle: '',
    imgContent: ''
  },
  modalForm: {
    show: false,
    titleForm: '',
    data: null
  },
  reloadData: false
}

const dashboard = {
  reloadRit1: false,
  reloadRit2: false,
}

const trayek = {
  filters: {
    search: null,
    isTol: null,
    classId: null
  },
  form: {
    step1: {
      name: '',
      code: '',
      class: null,
      startDate: '',
      endDate: '',
      isTol: false,
      rits: [
        { rit: null, expense: null },
        { rit: null, expense: null }
      ]
    },
    step2: {
      data: []
    }
  },
  formStep: 1,
  path: '',
  listBus: [],
  isReload: false
}

const headerButton = {
  isShow: false,
  label: '',
  route: '',
  isShowDialogForm: false
}

const vehicleMutation: IVehicleMutation = {
  firstBus: null,
  secondBus: null,
  description: '',
  formStep: 1
}

type Nullable<T> = T | undefined | null
const userProfile: Nullable<IUserProfile> = null

const state: IRootState = {
  network: networking,
  device: deviceType,
  loading: false,
  toggleMenu: true,
  toggleMinimizeMenu: false,
  toggleSidebar: false,
  hjpTable,
  hjpDataList,
  userProfile,
  selectedPassenger: null,
  selectedPriceChange: null,
  globalSettingHjex: null,
  togglePasswordDialog: false,
  dashboard,
  trayek,
  fieldSearch: null,
  headerButton,
  vehicleMutation
}

export default state
