import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IApprovePpState } from './model'
import { IRootState } from '@/store/model'

const states: IApprovePpState = {
  id: '',
  isReload: false
}

const mutations: MutationTree<IApprovePpState> = {
  SET_RELOAD_APPROVE_PP: (state, isReload: boolean, id = '') => {
    state.id = id
    state.isReload = isReload
    if (isReload) {
      setTimeout(() => {
        state.id = ''
        state.isReload = !isReload
      }, 2000)
    }
  },
}

const actions: ActionTree<IApprovePpState, IRootState> = {
  setReloadApprovePp: async ({ commit }, id) => {
    commit('SET_RELOAD_APPROVE_PP', true, id)
  },
}

const getters: GetterTree<IApprovePpState, IRootState> = {
  getReloadApprovePp(state) {
    return state.isReload
  },
}

export default {
  namespaced: true,
  state: states,
  mutations,
  actions,
  getters
}
