
import { ref, computed, defineComponent, toRefs } from 'vue'
import Paginator, { PageState } from 'primevue/paginator'

interface IPropsPaginator {
  rows: number
  totalRecords: number
  rowsPerPageOptions: Array<number> | null
  template: string
}

export default defineComponent({
  components: {
    Paginator
  },
  props: {
    rows: {
      type: Number,
      default: 10
    },
    totalRecords: {
      type: Number,
      default: 0
    },
    rowsPerPageOptions: {
      type: Array,
      default: null
    },
    template: {
      type: String,
    }
  },
  emits: ['onPage'],
  setup(props, { emit }) {
    const row = ref(props.rows)
    const rowsPerPageOption = ref(props.rowsPerPageOptions)
    const total = computed(() => props.totalRecords)
    const { template } = toRefs(props)
    const onPage = (event: PageState) => {
      emit('onPage', event)
    }
    return {
      row,
      total,
      onPage,
      template,
      rowsPerPageOption
    }
  }
})
