/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-shadow */
import { IGlobalSettingHjex } from '@/utils/interfaces/iGlobalSetting'
import { GetterTree } from 'vuex'
import { IRootState } from './model'

const getters: GetterTree<IRootState, IRootState> = {
  reloadTable(state) {
    return state.hjpTable.reloadTable
  },
  reloadDataList(state) {
    return state.hjpDataList.reloadData
  },
  globalSettingHjex(state) {
    const filterArr = (code: string) => state?.globalSettingHjex?.filter((v: IGlobalSettingHjex) => v.Code === code)[0]
    return {
      apiKeyOtp: filterArr('ApiKeyOtp'),
      tokenCompanyOtp: filterArr('TokenCompanyOtp'),
      tokenOtp: filterArr('TokenOtp'),
      kataOtp: filterArr('KataOtp')
    }
  },
  getReloadDashboardRit1Table(state) {
    return state.dashboard.reloadRit1
  },
  getReloadDashboardRit2Table(state) {
    return state.dashboard.reloadRit2
  },
  getSearchField(state) {
    return state.fieldSearch
  },
  getTrayekHeadFilters(state) {
    return state.trayek.filters
  },
  getTrayekFormStep(state) {
    return state.trayek.formStep
  }
}

export default getters
