import { App } from 'vue'
import { setupCalendar } from 'v-calendar'
import 'v-calendar/dist/style.css'

export default {
  install: (app: App<Element>) => {
    const vue = app
    vue.use(setupCalendar, {})
  }
}
