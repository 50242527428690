import uiState from './state'
import uiActions from './actions'
import uiMutations from './mutations'
import uiGetters from './getters'

export default {
  namespaced: true,
  state: uiState,
  mutations: uiMutations,
  actions: uiActions,
  getters: uiGetters
}
