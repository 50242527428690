import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "mb-6" }
const _hoisted_3 = { class: "content-data-list" }
const _hoisted_4 = { class: "container-spinner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHjpTable = _resolveComponent("HeaderHjpTable")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Paginator = _resolveComponent("Paginator")!
  const _component_Card = _resolveComponent("Card")!
  const _component_HjpSideBar = _resolveComponent("HjpSideBar")!
  const _component_HjpCofirmModal = _resolveComponent("HjpCofirmModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "card-no-shadow wrap-hjp-data-list" }, _createSlots({
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"70px","height":"70px"},
              class: "spinner-data-list"
            })
          ], 512), [
            [_vShow, _ctx.isLoading]
          ]),
          (!_ctx.isLoading)
            ? _renderSlot(_ctx.$slots, "content", {
                key: 0,
                dataItems: _ctx.dataSource
              })
            : _createCommentVNode("", true),
          (_ctx.dataSource.length === 0 && !_ctx.isLoading && _ctx.isCustomNoData)
            ? _renderSlot(_ctx.$slots, "noData", { key: 1 })
            : (_ctx.dataSource.length === 0 && !_ctx.isLoading && !_ctx.isCustomNoData)
              ? (_openBlock(), _createBlock(_component_EmptyState, {
                  key: 2,
                  textNoData: _ctx.textNoData
                }, null, 8, ["textNoData"]))
              : _createCommentVNode("", true)
        ])
      ]),
      footer: _withCtx(() => [
        (_ctx.totalRecords > 0)
          ? (_openBlock(), _createBlock(_component_Paginator, {
              key: 0,
              rows: _ctx.paginate.pageSize,
              totalRecords: _ctx.totalRecords,
              onOnPage: _ctx.onChangePage
            }, null, 8, ["rows", "totalRecords", "onOnPage"]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.showHeader)
        ? {
            name: "title",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_HeaderHjpTable, {
                  titleHeader: _ctx.headerTitle,
                  titleButton: _ctx.buttonTitle,
                  placeholder: _ctx.searchPlaceholder,
                  onRedirectAdd: _ctx.onClickBtnAdd,
                  onOnSearch: _ctx.onSearch,
                  showAddBtn: _ctx.showAddBtn
                }, null, 8, ["titleHeader", "titleButton", "placeholder", "onRedirectAdd", "onOnSearch", "showAddBtn"])
              ])
            ])
          }
        : undefined
    ]), 1024),
    _createVNode(_component_HjpSideBar, {
      show: _ctx.showModalForm,
      onHideDialog: _ctx.hideModalForm,
      confirmDialogDelete: _ctx.confirmDialogDelete,
      onOnDelete: _ctx.submitFromDialog
    }, _createSlots({ _: 2 }, [
      (_ctx.showModalForm)
        ? {
            name: "form",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "form")
            ])
          }
        : undefined
    ]), 1032, ["show", "onHideDialog", "confirmDialogDelete", "onOnDelete"]),
    _createVNode(_component_HjpCofirmModal, {
      show: _ctx.showConfirmDialog,
      title: _ctx.confirmDialogDelete?.title,
      subTitle: _ctx.confirmDialogDelete?.subTitle,
      btnSubmitTitle: _ctx.confirmDialogDelete?.btnSubmitTitle,
      onHideDialog: _ctx.hideDialogConfirmation,
      onOnSubmit: _ctx.submitFromDialog
    }, null, 8, ["show", "title", "subTitle", "btnSubmitTitle", "onHideDialog", "onOnSubmit"])
  ]))
}