import { StatusVerbalCode } from '@/utils/enum/StatusVerbal'

interface IColorStatusVerbal {
  color: string,
  backgroundColor: string
}

export default (status: string): IColorStatusVerbal => {
  let color = '#fff'
  let backgroundColor = '#fff'
  switch (status) {
    case StatusVerbalCode.Process:
      color = '#2EC4A0'
      backgroundColor = '#EBFFF1'
      break
    case StatusVerbalCode.Penalty:
      color = '#FFA800'
      backgroundColor = '#FFF9EE'
      break
    case StatusVerbalCode.Cancelled:
      color = '#FF5E5E'
      backgroundColor = 'rgba(255, 74, 74, 0.12)'
      break
    case StatusVerbalCode.Closed:
      color = '#90A4AE'
      backgroundColor = '#EFF5FF'
      break
    default:
      color = '#fff'
      backgroundColor = '#fff'
      break
  }
  return {
    color,
    backgroundColor
  }
}
