import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/img/icon/alert-red.svg'


const _hoisted_1 = { id: "tabs-refund" }
const _hoisted_2 = { class: "p-tabview-title" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  class: "ml-2",
  alt: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_TabView, {
      class: "tab-view-primary",
      activeIndex: _ctx.active,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrHeader, (item) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            key: item.value
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(item.title), 1),
              (item.error)
                ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}